import React from "react";

export const VerifyCard = ({ name, email, phone, image_link, setShowPopup,member_id }) => {
  return (
    <div className="admin-card">
      <div className="card-inner-div">
        <div className="left-div">
          <p>Name:</p>
        </div>
        <div className="right-div">
          <p>{name}</p>
        </div>
      </div>
      <div className="card-inner-div">
        <div className="left-div">
          <p>Email:</p>
        </div>
        <div className="right-div">
          <p>{email}</p>
        </div>
      </div>
      <div className="card-inner-div">
        <div className="left-div">
          <p>Phone:</p>
        </div>
        <div className="right-div">
          <p>{phone}</p>
        </div>
      </div>
      <div className="card-inner-div">
        <div className="left-div">
          <p>Image:</p>
        </div>
        <div className="right-div">
          <img
            onClick={() => {
              setShowPopup(image_link);
            }}
            className="image"
            src={image_link}
            alt="membership proof"
          />
        </div>
      </div>

      <div className="button-holder">
        <button className="reject">Reject</button>
        <button className="approve">Approve</button>
      </div>
    </div>
  );
};