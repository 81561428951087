import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "../css/userProfile/UserProfile.css";
import "../css/userProfile/UserProfileMediaQuery.css";

import { Loader } from "../components/Loader";
import profile_image from "../assets/images/userProfile/profile_image.png";
import UserProfileInput from "../components/UserProfileInput";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function UserProfile({ setLoggedIn }) {
  const [inputs, setInputs] = useState({
    //state to store the user details
    name: "",
    email: "",
    phoneNumber: "",
    graduation_year: "",
    isMember: "",
    membershipNumber: "",
    eventAttended: "",
  });

  const [admin, setAdmin] = useState(false); //state to store the membership status

  const [membershipStatus, setMembershipStatus] = useState(""); //state to store the membership status

  const [loader, setLoader] = useState(true); //state to store the loader status

  const [focused, setFocused] = useState({
    //state to store the focus status of the input fields
    name: false,
    phoneNumber: false,
    graduation_year: false,
  });

  const [edit, setEdit] = useState(false); //state to store the edit status

  const [registered, setRegistered] = useState(false); //state to store the edit status

  const years = Array.from(
    //array to store the graduation years
    { length: 5 },
    (_, i) => new Date().getFullYear() + i
  );

  const notifySuccess = (
    e //function to display the success messages
  ) =>
    toast.success(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notify = (
    e //function to display the error messages
  ) =>
    toast.error(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const navigate = useNavigate(); //function to navigate to different pages

  const onKeyDown = (evt) => {
    //function to prevent the user from entering alphabets in the phone number field
    (evt.key === "e" ||
      evt.key === "+" ||
      evt.key === "." ||
      evt.key === "-") &&
      evt.preventDefault();
  };

  const maxLengthCheck = (object) => {
    //function to prevent the user from entering more than 10 digits in the phone number field
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const onFocus = (e) => {
    //function to set the focus status of the input fields
    setFocused({ ...focused, [e.target.name]: true });
  };

  const onBlur = (e) => {
    //function to set the focus status of the input fields
    setFocused({ ...focused, [e.target.name]: false });
  };

  const editProfile = () => {
    //function to toggle the edit status
    if (edit) {
      if (inputs.name === "") {
        notify("Name cannot be empty");
        return;
      }

      if (inputs.phoneNumber === "") {
        notify("Phone Number cannot be empty");
        return;
      }

      if (inputs.graduation_year === "") {
        notify("Graduation Year cannot be empty");
        return;
      }
      setEdit(false);
      saveChanges();
    } else {
      setEdit(true);
    }
  };

  const handleChange = (event) => {
    //function to handle the changes made in the input fields
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    //useEffect to change the width of the input fields
    let temp = document.getElementsByClassName("edit");
    if (temp) {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].value.length === 0) {
          // temp[i].style.width = "145px";
          if (focused[temp[i].name]) {
            temp[i].style.borderBottom = "2px solid white";
          } else {
            temp[i].style.borderBottom = "2px solid #868e96";
          }
        } else {
          // temp[i].style.width = "70%";
          temp[i].style.borderBottom = "2px solid #101113";
        }
      }
    }
    if (!edit) {
      let temp = document.getElementsByClassName("UDinput");
      if (temp) {
        for (let i = 0; i < temp.length; i++) {
          // temp[i].style.width = "120px";
          // temp[i].style.width = "70%";
          // temp[i].style.borderBottom = "2px solid #101113";
        }
      }
    }
  }, [focused, inputs, edit]);

  const CheckAdmin = async () => {
    setLoader(true);
    let config = {
      //config to send the token in the header
      headers: {
        "Content-Type": "application/json",
        authtoken: localStorage.getItem("token"),
      },
    };

    let body = {};

    try {
      const response = await axios.get(
        //post request to the backend
        "https://ieeevitpune.com/" + "admin/checkAdmin",
        config,
        body
      );
      if (response.data.success === true) {
        setAdmin(true);
      }
    } catch (err) {}
    setLoader(false);
  };

  useEffect(() => {
    //useEffect to check if the user is logged in or not
    if (!localStorage.getItem("token")) {
      navigate("/Login");
    }
    CheckAdmin();
    getUser();
    // eslint-disable-next-line
  }, []);

  const getUser = async () => {
    //function to get the user details
    const token = localStorage.getItem("token");

    let config = {
      //config to send the token in the header
      headers: {
        "Content-Type": "application/json",
        authtoken: token,
      },
    };

    let body = {};

    try {
      const response = await axios.get(
        //post request to the backend
        "https://ieeevitpune.com/" + "user/getroute",
        config,
        body
      );
      if (response.data.success === true) {
        setInputs({
          name: response.data.data.name,
          email: response.data.data.email,
          phoneNumber: response.data.data.phoneNumber,
          graduation_year: response.data.data.graduation_year,
          isMember: response.data.data.isMember,
          membershipNumber: response.data.data.membershipNumber,
          eventAttended: response.data.data.eventsAttended,
        });
        if (response.data.data.isMember === 1) {
          setMembershipStatus("Active");
        } else if (response.data.data.isMember === -1) {
          setMembershipStatus("Verification Pending");
        } else {
          setMembershipStatus("Inactive");
        }
      } else {
        notify(response.data.message);
        console.log(response);
        localStorage.removeItem("token");
        navigate("/Login");
      }
    } catch (err) {
      notify(err.response.data.message);
      console.log(err);
      localStorage.removeItem("token");
      navigate("/Login");
    }
    setLoader(false);
  };

  const saveChanges = async () => {
    setLoader(true);

    //function to save the changes made by the user
    const token = localStorage.getItem("token");
    var decoded = jwt_decode(token);

    let config = {
      headers: {
        "Content-Type": "application/json",
        authtoken: token,
      },
    };
    try {
      const response = await axios.put(
        //post request to the backend
        "https://ieeevitpune.com/" + "user/updateuser/" + decoded.userId + "/",
        {
          name: inputs.name,
          phoneNumber: inputs.phoneNumber,
          graduation_year: inputs.graduation_year,
        },
        config
      );
      if (response.data.success === true) {
        notifySuccess(response.data.msg);
        // console.log(response);
        // console.log(inputs);
      } else {
        //if the login is unsuccessful
        notify(response.data.msg);
      }
    } catch (err) {
      notify(err.response.data.msg);
    }
    setLoader(false);
  };

  const getTechsummitTicket = async () => {
    setLoader(true);

    //function to save the changes made by the user

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let body = {};

    try {
      const response = await axios.get(
        //post request to the backend
        "https://ieeevitpune.com/" + "admin/getRegisteredUser/" + inputs.email,
        body,
        config
      );
      if (response.data.success === true) {
        setRegistered(true);
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };

  useEffect(() => {
    getTechsummitTicket();
  }, [inputs.email]);

  const logout = () => {
    localStorage.removeItem("token");
    setLoggedIn(false);
    navigate("/");
  };

  return (
    <div className="userProfile">
      {loader ? <Loader /> : null}
      <div className="userDetails">
        <div className="userDetailsContainer">
          <div className="yourProfileContainer">
            <p className="yourProfile">Your Profile</p>
          </div>
          <div className="userDetailsContent">
            <div className="flexContainer1">
              <div className="profileImageContainer">
                <img className="profileImage" src={profile_image} alt="" />
              </div>
              <div>
                <p className="userFullName">{inputs?.name}</p>
                <small className="smallEmail">{inputs?.email}</small>
              </div>
              <div className="flexContainer2">
                <button onClick={editProfile} className="UPbtn">
                  {edit ? "Save Changes" : "Edit Profile"}
                </button>
              </div>
            </div>
            <div className="UDform">
              <div className="UDcontainer">
                <UserProfileInput
                  labelName="Name"
                  name="name"
                  edit={edit}
                  value={inputs?.name}
                  handleChange={handleChange}
                  inputs={inputs}
                  maxLengthCheck={maxLengthCheck}
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
              </div>
              <div className="UDcontainer">
                <label className="UDtitle">Email: </label>
                <p className="UDInput2">{inputs?.email}</p>
              </div>
              <div className="UDcontainer">
                <UserProfileInput
                  labelName="Phone Number"
                  name="phoneNumber"
                  edit={edit}
                  type="number"
                  value={inputs?.phoneNumber}
                  handleChange={handleChange}
                  inputs={inputs}
                  maxLengthCheck={maxLengthCheck}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyDown={onKeyDown}
                  maxLength="10"
                />
              </div>
              <div className="UDcontainer">
                <label className="UDtitle">Graduation Year: </label>
                <select
                  name="graduation_year"
                  value={inputs?.graduation_year}
                  onChange={handleChange}
                  className="UDYear"
                  disabled={!edit}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="UPlogoutBtnContainer">
          <button onClick={logout} className="UPlogoutBtn UPbtn">
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
