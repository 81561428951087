import Aarya_keskar from "../assets/images/aboutUs/execom/Aarya_keskar.png";
import Aditya_Dighe from "../assets/images/aboutUs/execom/Aditya_Dighe.png";
import Aniketh_pala from "../assets/images/aboutUs/execom/Aniketh_pala.png";
import Anushka_Khandelwal from "../assets/images/aboutUs/execom/Anushka_Khandelwal.png";
import Apurva_Kota from "../assets/images/aboutUs/execom/Apurva_Kota.png";
import Arya_Alurkar from "../assets/images/aboutUs/execom/Arya_Alurkar.png";
import Arya_Lokhande from "../assets/images/aboutUs/execom/Arya_Lokhande.png";
import Aryan_Mundra from "../assets/images/aboutUs/execom/Aryan_Mundra.png";
import Atharva_Ansingkar from "../assets/images/aboutUs/execom/Atharva_Ansingkar.png";
import Atharva_Shinde from "../assets/images/aboutUs/execom/Atharva_Shinde.png";
import Devashish_Kanhere from "../assets/images/aboutUs/execom/Devashish_Kanhere.png";
import Dilpreet_Sahney from "../assets/images/aboutUs/execom/Dilpreet_Sahney.png";
import Gajanan_Baviskar from "../assets/images/aboutUs/execom/Gajanan_Baviskar.png";
import Harsh_Mahale from "../assets/images/aboutUs/execom/Harsh_Mahale.png";
import Harshada_Deshingkar from "../assets/images/aboutUs/execom/Harshada_Deshingkar.png";
import Harshwardhan_Patil from "../assets/images/aboutUs/execom/Harshwardhan_Patil.png";
import Hritesh_Maikap from "../assets/images/aboutUs/execom/Hritesh_Maikap.png";
import Ishita_Jasuja from "../assets/images/aboutUs/execom/Ishita_Jasuja.png";
import Mokshad_Nemade from "../assets/images/aboutUs/execom/Mokshad_Nemade.png";
import Nidhish_Wakodikar from "../assets/images/aboutUs/execom/Nidhish_Wakodikar.png";
import Parth_petkar from "../assets/images/aboutUs/execom/Parth_petkar.png";
import Priyal_Patange from "../assets/images/aboutUs/execom/Priyal_Patange.png";
import Qusai_Shergardwala from "../assets/images/aboutUs/execom/Qusai_Shergardwala.png";
import Rijul_Sidanale from "../assets/images/aboutUs/execom/Rijul_Sidanale.png";
import Saif_Khan from "../assets/images/aboutUs/execom/Saif_Khan.png";
import Saish_Rathod from "../assets/images/aboutUs/execom/Saish_Rathod.png";
import Sarvesh_Bajare from "../assets/images/aboutUs/execom/Sarvesh_Bajare.png";
import Shreyas_Nagarkar from "../assets/images/aboutUs/execom/Shreyas_Nagarkar.png";
import Shruti_Sood from "../assets/images/aboutUs/execom/Shruti_Sood.png";
import Sneha_Jain from "../assets/images/aboutUs/execom/Sneha_Jain.png";
import Soham_Nimale from "../assets/images/aboutUs/execom/Soham_Nimale.png";
import Somrath_Bisen from "../assets/images/aboutUs/execom/Somrath_Bisen.png";
import Vaishnavi_Singh from "../assets/images/aboutUs/execom/Vaishnavi_Singh.png";
import Vasundhara_Bawake from "../assets/images/aboutUs/execom/Vasundhara_Bawake.png";

const execom = [
  {
    imagee: Aarya_keskar,
    linkedine: "https://www.linkedin.com/in/aarya-keskar-689020260/",
    gite: "https://github.com/",
    namee: "Aarya Keskar",
    positione: "Management",
  },
  {
    imagee: Aditya_Dighe,
    linkedine: "https://www.linkedin.com/in/aditya-dighe/",
    gite: "https://github.com/digheaditya",
    namee: "Aditya Dighe",
    positione: "Technical",
  },
  {
    imagee: Aniketh_pala,
    linkedine: "https://www.linkedin.com/in/aniketh-pala-415290257/ ",
    gite: "https://github.com/Aniketh4",
    namee: "Aniketh Pala",
    positione: "Technical",
  },
  {
    imagee: Anushka_Khandelwal,
    linkedine: "https://www.linkedin.com/in/anushka-khandelwal-74b14325a/",
    gite: "https://github.com/",
    namee: "Anushka Khandelwal",
    positione: "Management",
  },
  {
    imagee: Apurva_Kota,
    linkedine: "https://www.linkedin.com/in/apurva-kota-714084252/",
    gite: "https://github.com/apurva-kota",
    namee: "Apurva Kota",
    positione: "Social Media",
  },
  {
    imagee: Arya_Alurkar,
    linkedine: "https://www.linkedin.com/in/arya-alurkar-b60a8827b/",
    gite: "https://github.com/Arya911",
    namee: "Arya Alurkar",
    positione: "Technical",
  },
  {
    imagee: Arya_Lokhande,
    linkedine: "https://www.linkedin.com/in/arya-lokhande-398316221/",
    gite: "https://github.com/",
    namee: "Arya Lokhande",
    positione: "Technical",
  },
  {
    imagee: Aryan_Mundra,
    linkedine: "https://www.linkedin.com/in/aryan-mundra-512b64218/",
    gite: "https://github.com/midmundra",
    namee: "Aryan Mundra",
    positione: "Content",
  },
  {
    imagee: Atharva_Ansingkar,
    linkedine: "https://www.linkedin.com/in/atharva-ansingkar-793134142/",
    gite: "https://github.com/atharvaansingkar",
    namee: "Atharva Ansingkar",
    positione: "Design & Multimedia",
  },
  {
    imagee: Atharva_Shinde,
    linkedine: "https://www.linkedin.com/",
    gite: "https://github.com/",
    namee: "Atharva Shinde",
    positione: "Content",
  },
  {
    imagee: Devashish_Kanhere,
    linkedine: "https://www.linkedin.com/in/devashish-kanhere/",
    gite: "https://github.com/devashishkanhere",
    namee: "Devashish Kanhere",
    positione: "Technical",
  },
  {
    imagee: Dilpreet_Sahney,
    linkedine: "https://www.linkedin.com/in/dilpreet-singh-sahney-302094250/",
    gite: "https://github.com/DILPREET1910",
    namee: "Dilpreet Sahney",
    positione: "Technical",
  },
  {
    imagee: Gajanan_Baviskar,
    linkedine: "https://www.linkedin.com/in/gajanan-baviskar-8942ba264/",
    gite: "https://github.com/gajananbaviskar",
    namee: "Gajanan Baviskar",
    positione: "PR & Outreach",
  },
  {
    imagee: Harsh_Mahale,
    linkedine: "https://www.linkedin.com/in/harsh-mahale-858b2725b/",
    gite: "https://github.com/msharsh1",
    namee: "Harsh Mahale",
    positione: "Management",
  },
  {
    imagee: Harshada_Deshingkar,
    linkedine: "https://www.linkedin.com/in/harshada-deshingkar-7330a0248/",
    gite: "https://github.com/happiesthd",
    namee: "Harshada Deshingkar",
    positione: "Management",
  },
  {
    imagee: Harshwardhan_Patil,
    linkedine: "https://www.linkedin.com/in/harshwardhan-patil-b95ab0241/",
    gite: "https://github.com/HarshwardhanPatil07",
    namee: "Harshwardhan Patil",
    positione: "Finance",
  },
  {
    imagee: Hritesh_Maikap,
    linkedine: "https://www.linkedin.com/in/hritesh-maikap-7aaa76246/",
    gite: "https://github.com/",
    namee: "Hritesh Maikap",
    positione: "Technical",
  },
  {
    imagee: Ishita_Jasuja,
    linkedine: "https://www.linkedin.com/in/ishita-jasuja-52286b257/",
    gite: "https://github.com/ishitajasuja",
    namee: "Ishita Jasuja",
    positione: "Technical",
  },
  {
    imagee: Mokshad_Nemade,
    linkedine: "https://www.linkedin.com/in/mokshad-nemade-619671238/",
    gite: "https://github.com/",
    namee: "Mokshad Nemade",
    positione: "Management",
  },
  {
    imagee: Nidhish_Wakodikar,
    linkedine: "https://www.linkedin.com/in/nidhish-wakodikar-b00117259/",
    gite: "https://github.com/",
    namee: "Nidhish Wakodikar",
    positione: "Technical",
  },
  {
    imagee: Parth_petkar,
    linkedine: "https://www.linkedin.com/in/parth-petkar-7a6a9025b/",
    gite: "https://github.com/parthpetkar",
    namee: "Parth Petkar",
    positione: "PR & Outreach",
  },
  {
    imagee: Priyal_Patange,
    linkedine: "https://www.linkedin.com/in/priyal-patange-b56904282/",
    gite: "https://github.com/",
    namee: "Priyal Patange",
    positione: "PR & Outreach",
  },
  {
    imagee: Qusai_Shergardwala,
    linkedine: "https://www.linkedin.com/in/qusai-shergardwala-8a6404259/",
    gite: "https://github.com/qusaii21",
    namee: "Qusai Shergardwala",
    positione: "PR & Outreach",
  },
  {
    imagee: Rijul_Sidanale,
    linkedine: "https://www.linkedin.com/in/rijul-sidanale-81955024b/",
    gite: "https://github.com/rijul31",
    namee: "Rijul Sidanale",
    positione: "Technical",
  },
  {
    imagee: Saif_Khan,
    linkedine: "https://www.linkedin.com/in/saif-khan-31602b276/",
    gite: "https://github.com/Saif-khan7",
    namee: "Saif Khan",
    positione: "Management",
  },
  {
    imagee: Saish_Rathod,
    linkedine: "https://www.linkedin.com/in/saish-rathod-18a4b3260",
    gite: "https://github.com/syrathod",
    namee: "Saish Rathod",
    positione: "Technical",
  },
  {
    imagee: Sarvesh_Bajare,
    linkedine: "https://www.linkedin.com/in/sarvesh-bajare-bb181a252/",
    gite: "https://github.com/geekatbest",
    namee: "Sarvesh Bajare",
    positione: "Technical",
  },
  {
    imagee: Shreyas_Nagarkar,
    linkedine: "https://www.linkedin.com/in/shreyas-nagarkar-156237280/",
    gite: "https://github.com/Shreyas454",
    namee: "Shreyas Nagarkar",
    positione: "Finance",
  },
  {
    imagee: Shruti_Sood,
    linkedine: "https://www.linkedin.com/in/shruti-sood-306b6a258/",
    gite: "https://github.com/shrutisood03",
    namee: "Shruti Sood",
    positione: "Design & Multimedia",
  },
  {
    imagee: Sneha_Jain,
    linkedine: "https://www.linkedin.com/in/sneha-jain-473357261/",
    gite: "https://github.com/JainSneha6",
    namee: "Sneha Jain",
    positione: "Content",
  },

  {
    imagee: Soham_Nimale,
    linkedine: "https://www.linkedin.com/in/soham-nimale-500692257/",
    gite: "https://github.com/snimale",
    namee: "Soham Nimale",
    positione: "Technical",
  },
  {
    imagee: Somrath_Bisen,
    linkedine: "https://www.linkedin.com/in/somrath-bisen-5549441aa/",
    gite: "https://github.com/Buzzingturnip88",
    namee: "Somrath Bisen",
    positione: "PR & Outreach",
  },
  {
    imagee: Vaishnavi_Singh,
    linkedine: "https://www.linkedin.com/in/vaishnavi-singh-bb2503253/",
    gite: "https://github.com/vaishnavi-23-10",
    namee: "Vaishnavi Singh",
    positione: "Design & Multimedia",
  },
  {
    imagee: Vasundhara_Bawake,
    linkedine: "https://www.linkedin.com/in/vasundhara-bawake-2b408321a/",
    gite: "https://github.com/",
    namee: "Vasundhara Bawake",
    positione: "Design & Multimedia",
  },
];

export default execom;
