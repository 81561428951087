import React, { useState, useEffect } from "react";
import "../css/admin/AdminDash.css";
import edit from "../assets/images/adminDash/edit.png";
import remove from "../assets/images/adminDash/remove.png";
import search from "../assets/images/adminDash/search.png";
import axios from "axios";
import { NavbarAdmin } from "../components/NavbarAdmin";
import { useNavigate } from "react-router-dom";
import { Loader } from "../components/Loader";

function AdminDash() {
  const [key, setKey] = useState("");
  const [adminkey, setAdminkey] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [allAdmin, setAllAdmin] = useState([]);

  const [adminloader, setAdminLoader] = useState(true);
  const [eventloader, setEventLoader] = useState(true);

  const navigate = useNavigate();

  const getStatus = (startDate, endDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (today < start) {
      return "Upcoming";
    } else if (today > end) {
      return "Completed";
    } else {
      return "Ongoing";
    }
  };

  const ViewDetails = (id, status) => {
    if (status === "Completed") {
      navigate("/event/getevent/" + id);
    } else {
      navigate("/admin/VerifyRegistration/" + id);
    }
  };

  const removeEvent = async (id) => {
    let config = {
      //config to send the token in the header
      headers: {
        "Content-Type": "application/json",
        authtoken: localStorage.getItem("token"),
      },
    };
    try {
      const response = await axios.delete(
        //post request to the backend
        "https://ieeevitpune.com/" + "events/delete/" + id,
        config
      );
      if (response.data.success === true) {
        await fetchAllEvents();
      } else {
        // console.log(response);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const removeAdmin = async (email) => {
    let config = {
      //config to send the token in the header
      headers: {
        "Content-Type": "application/json",
        authtoken: localStorage.getItem("token"),
      },
    };

    const body = {
      email: email,
    };

    try {
      const response = await axios.put(
        //post request to the backend
        "https://ieeevitpune.com/" + "admin/removeAsAdmin",
        body,
        config
      );
      if (response.data.success === true) {
        // console.log(response);
        await fetchAllAdmins();
      } else {
        //if the login is unsuccessful
      }
    } catch (err) {}
  };

  const fetchAllEvents = async () => {
    setEventLoader(true);
    let config = {
      //config to send the token in the header
      headers: {
        "Content-Type": "application/json",
      },
    };
    let body = {};
    try {
      const response = await axios.get(
        //post request to the backend
        "https://ieeevitpune.com/" + "events/getAllEvents",
        body,
        config
      );
      if (response.data.success === true) {
        const sortedData = response.data.data.info.sort((a, b) =>
          b.date_time_info.details[0].date.localeCompare(
            a.date_time_info.details[0].date
          )
        );
        setAllEvents(sortedData);
        setEventLoader(false);
      } else {
        // console.log(response);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const fetchAllAdmins = async () => {
    setAdminLoader(true);
    let config = {
      //config to send the token in the header
      headers: {
        "Content-Type": "application/json",
        authtoken: localStorage.getItem("token"),
      },
    };

    let body = {};

    try {
      const response = await axios.get(
        //post request to the backend
        "https://ieeevitpune.com/" + "admin/getAllAdmins",
        config,
        body
      );
      if (response.data.success === true) {
        setAllAdmin(response.data.data.admindetails);
      } else {
      }
    } catch (err) {}
    setAdminLoader(false);
  };

  const CheckAdmin = async () => {
    setAdminLoader(true);
    let config = {
      //config to send the token in the header
      headers: {
        "Content-Type": "application/json",
        authtoken: localStorage.getItem("token"),
      },
    };

    let body = {};

    try {
      const response = await axios.get(
        //post request to the backend
        "https://ieeevitpune.com/" + "admin/checkAdmin",
        config,
        body
      );
      if (response.data.success === false) {
        navigate("/");
      }
    } catch (err) {
      navigate("/");
    }
    setAdminLoader(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/PageNotFound");
    }
    CheckAdmin();
    fetchAllEvents();
    fetchAllAdmins();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="adminDashContainer">
      <NavbarAdmin />
      {adminloader || eventloader ? <Loader /> : null}
      <h1 className="heading1"> IEEE Admin Dashboard</h1>
      <div className="container3">
        <h1 className="eventsList" id="eventlist">
          Events Lists
        </h1>
        <div className="searchBox">
          <img
            src={search}
            className="ADimg searchImg"
            height="35px"
            alt=""
          ></img>
          <input
            type="text"
            value={key}
            className="ADinput"
            onChange={(e) => {
              setKey(e.target.value);
            }}
          ></input>
        </div>
        <button className="addEvent" type="submit">
          Add Event
        </button>
      </div>

      <div className="container">
        <div className="tableHolder">
          <table className="eventsTable">
            <thead></thead>
            <tbody>
              <tr>
                <th>Sr No</th>
                <th>Event Name</th>
                <th>Event Date</th>
                <th>Status </th>
                <th>Actions </th>
                <th>Event Details</th>
              </tr>

              {allEvents
                .filter((element) => {
                  if (element.event_name?.toLowerCase()?.startsWith(key)) {
                    return element;
                  } else {
                    return null;
                  }
                })
                .map((row, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.event_name}</td>
                    <td>
                      {new Date(row.date_time_info.details[0].date).getDate() +
                        "/" +
                        (new Date(
                          row.date_time_info.details[0].date
                        ).getMonth() +
                          1) +
                        "/" +
                        new Date(
                          row.date_time_info.details[0].date
                        ).getFullYear()}
                    </td>
                    <td>
                      {getStatus(
                        row.date_time_info.details[0].date,
                        row.date_time_info.details[
                          row.date_time_info.details.length - 1
                        ].date
                      )}
                    </td>
                    <td>
                      <div className="btnHolder">
                        <button className="Button">
                          <img
                            src={edit}
                            alt="edit_image"
                            className="ADimg"
                            width="45px"
                            height="45px"
                          ></img>
                        </button>
                        <button
                          className="Button"
                          onClick={() => removeEvent(row._id)}
                        >
                          <img
                            src={remove}
                            className="ADimg"
                            alt="edit_image"
                            width="45px"
                            height="45px"
                          ></img>
                        </button>
                      </div>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          ViewDetails(
                            row._id,
                            getStatus(
                              row.date_time_info.details[0].date,
                              row.date_time_info.details[
                                row.date_time_info.details.length - 1
                              ].date
                            )
                          )
                        }
                        className="ADviewDetails"
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </div>

      {/* ADmins LIst  */}

      <div className="containerAdmin">
        <h1 className="adminsList" id="eventlist">
          Admins Lists
        </h1>
        <div className="searchBox">
          <img
            src={search}
            className="ADimg searchImg"
            height="35px"
            alt=""
          ></img>
          <input
            type="text"
            className="ADinput"
            value={adminkey}
            onChange={(e) => {
              setAdminkey(e.target.value);
            }}
          ></input>
        </div>
        <button className="addAdmin" type="submit">
          Add Admin
        </button>
      </div>

      <div className="container">
        <div className="tableHolder">
          <table className="adminTable">
            <thead></thead>
            <tbody>
              <tr>
                <th>Sr No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>

              {allAdmin
                .filter((element) => {
                  if (element.name.startsWith(adminkey)) {
                    return element;
                  } else {
                    return null;
                  }
                })
                .map((row, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.name}</td>
                    <td>{row.email}</td>
                    <td>
                      <div className="btnHolder">
                        <button
                          className="Button"
                          onClick={() => removeAdmin(row.email)}
                        >
                          <img
                            src={remove}
                            className="ADimg"
                            alt="edit_image"
                            width="45px"
                            height="45px"
                          ></img>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminDash;
