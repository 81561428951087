import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { useState , useEffect } from 'react';
import { useState, useRef, useEffect } from "react";
import "../css/navbar/NavBar2.css";
import image from "../assets/images/navbar/IEEE_VITP_logo.png";
import NavBar_icon from "../assets/images/navbar/NavBar_icon.png";
// import { FaBars, FaTimes } from "react-icons/fa";

export function NavbarAdmin({ scrollValue }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [isClicked, setClicked] = useState(false);

  const toggle = () => {
    setClicked(!isClicked);
  };

  const navRef = useRef();

  // apply class nav-black when scroll value is less than 100
  useEffect(() => {
    if (
      scrollValue < 100 &&
      (location.pathname === "/" || location.pathname === "/About")
    ) {
      navRef.current.classList.add("nav-black");
    } else {
      navRef.current.classList.remove("nav-black");
    }
    // eslint-disable-next-line
  }, [scrollValue]);

  return (
    <div ref={navRef} className="NavBar">
      <div>
        <img
          src={image}
          onClick={() => {
            navigate("/");
          }}
          className="Logo"
          alt="IEEE VIT'P"
        />
      </div>

      <div className="Elements">
        <div className={`navList navbarDesktop  ${isClicked ? "" : "hideNav"}`}>
          <Link
            to="/admin/AdminPage"
            className={`${
              location.pathname === "/admin/AdminDash" ? "active" : ""
            }`}
            onClick={toggle}
          >
            Admin Home
          </Link>

          <Link
            to="/admin/MemberVerify"
            className={`${
              location.pathname === "/admin/MemberVerify" ? "active" : ""
            }`}
            onClick={toggle}
          >
            Member Verify
          </Link>
          <Link
            to="/admin/TicketScanner"
            className={`${
              location.pathname === "/admin/TicketScanner" ? "active" : ""
            }`}
            onClick={toggle}
          >
            Ticket Scanner
          </Link>
        </div>
        <div className="UnderLine_container">
          <div className="UnderLine" />
        </div>
        <div className="nav-btn" onClick={toggle}>
          {/* <FaBars /> */}
          <img src={NavBar_icon} alt="NavBar" className="nav-btn" />
        </div>
      </div>
    </div>
  );
}
