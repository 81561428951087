import Aditya from "../assets/images/aboutUs/core/Aditya_Mhaske.png";
import Aneesh from "../assets/images/aboutUs/core/Aneesh_Pathak.png";
import Digvijay from "../assets/images/aboutUs/core/Digvijay_Suryawanshi.png";
import Divyanshu from "../assets/images/aboutUs/core/Divyanshu_Chore.png";
import Jay from "../assets/images/aboutUs/core/Jay_Kankale.png";
import Mahi from "../assets/images/aboutUs/core/Mahi_Chrungoo.png";
import Mrunmayee from "../assets/images/aboutUs/core/Mrunmayee_Phadke.png";
import Om from "../assets/images/aboutUs/core/Om_Khode.png";
import Pranav from "../assets/images/aboutUs/core/Pranav_Joshi.png";
import Sahil from "../assets/images/aboutUs/core/Sahil_Patil.png";
import Samarth from "../assets/images/aboutUs/core/Samarth_Thosar.png";
import Tejas from "../assets/images/aboutUs/core/Tejas_Bharambe.png";
import Tushar from "../assets/images/aboutUs/core/Tushar_Nasery.png";

const core = [
  {
    imagec: Tushar,
    linkedinc: "https://www.linkedin.com/in/tushar-nasery/",
    gitc: "https://github.com/tusharn85",
    namec: "Tushar Nasery",
    positionc: "Chairperson",
  },
  {
    imagec: Mahi,
    linkedinc: "https://www.linkedin.com/in/mahi-chrungoo/",
    gitc: "https://github.com/MAHIC-1201",
    namec: "Mahi Chrungoo",
    positionc: "Vice Chairperson",
  },
  {
    imagec: Divyanshu,
    linkedinc: "https://www.linkedin.com/in/divyanshu-chore-2bb413223/",
    gitc: "https://github.com/",
    namec: "Divyanshu Chore",
    positionc: "Secretary",
  },
  {
    imagec: Aneesh,
    linkedinc: "https://www.linkedin.com/in/aneesh-pathak-a799bb22b/",
    gitc: "https://github.com/",
    namec: "Aneesh Pathak",
    positionc: "Management Head",
  },
  {
    imagec: Tejas,
    linkedinc: "https://www.linkedin.com/in/tejas-bharambe-8b0512230/",
    gitc: "https://github.com/TejassBharambe",
    namec: "Tejas Bharambe",
    positionc: "PR & Outreach Head",
  },
  {
    imagec: Jay,
    linkedinc: "https://www.linkedin.com/in/jay-kankale-543a41232/",
    gitc: "https://github.com/",
    namec: "Jay Kankale",
    positionc: "Coding Club Head",
  },
  {
    imagec: Sahil,
    linkedinc: "https://www.linkedin.com/in/sahil-patil17/",
    gitc: "https://github.com/sahil-patil17",
    namec: "Sahil Patil",
    positionc: "Coding Club Head",
  },
  {
    imagec: Samarth,
    linkedinc: "https://www.linkedin.com/in/samarth-thosar/",
    gitc: "https://github.com/samarth-thosar",
    namec: "Samarth Thosar",
    positionc: "Project Head",
  },
  {
    imagec: Om,
    linkedinc: "https://www.linkedin.com/in/om-khode/",
    gitc: "https://github.com/Om-Khode",
    namec: "Om Khode",
    positionc: "Project Head",
  },
  {
    imagec: Mrunmayee,
    linkedinc: "https://www.linkedin.com/in/mrunmayee-phadke-635060241",
    gitc: "https://github.com/Mrunmayee-762004",
    namec: "Mrunmayee Phadke",
    positionc: "Project Head",
  },
  {
    imagec: Pranav,
    linkedinc: "https://www.linkedin.com/in/pranav-joshi-168298231",
    gitc: "https://github.com/Pranav-JJ",
    namec: "Pranav Joshi",
    positionc: "Research Head",
  },
  {
    imagec: Aditya,
    linkedinc: "https://www.linkedin.com/in/aditya-mhaske-77a642223/",
    gitc: "https://github.com/MhaskeAditya42",
    namec: "Aditya Mhaske",
    positionc: "Multimedia Head",
  },
  {
    imagec: Digvijay,
    linkedinc: "https://www.linkedin.com/in/digvijay-suryawanshi-844576229/",
    gitc: "https://github.com/",
    namec: "Digvijay Suryawanshi",
    positionc: "Multimedia Head",
  },
];

export default core;
