import { NavBar } from "./components/NavBar2";
import HomePage from "./pages/HomePage";
import EventsPage from "./pages/EventsPage";
import AboutUs from "./pages/AboutUs";
import { Activities } from "./pages/Activities";
import Login from "./pages/Login";
import UserProfile from "./pages/UserProfile";
import AdminDash from "./pages/AdminDash";
import VerifyRegistration from "./pages/VerifyRegistration";
import MemberVerify from "./pages/MemberVerify";
import LoginUsingOTP from "./pages/LoginUsingOtp";
import EnterOTP from "./pages/EnterOTP";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import EmailSend from "./pages/EmailSend";
import Footer from "./components/Footer";
import ErrorPage from "./pages/ErrorPage";

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const getScrollValue = () => {
    const scrollValue = window.scrollY;
    return scrollValue;
  };

  const trackingId = "G-C21NGKVNJK";
  ReactGA.initialize(trackingId);

  const [loggedIn, setLoggedIn] = useState(false);

  const location = useLocation();

  const useScrollValue = () => {
    const [scrollValue, setScrollValue] = useState(getScrollValue());

    useEffect(() => {
      function handleScroll() {
        setScrollValue(getScrollValue());
      }

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return scrollValue;
  };

  const scrollValue = useScrollValue();

  return (
    <div>
      <NavBar
        scrollValue={scrollValue}
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
      />
      <ToastContainer />

      <Routes>
        {/* Pages to show to user without Login */}
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/Events" element={<EventsPage />}></Route>
        <Route path="/AboutUs" element={<AboutUs />}></Route>
        <Route path="/Activities" element={<Activities />}></Route>

        {/* Pages to show to user for Login */}
        <Route
          path="/Login"
          element={<Login setLoggedIn={setLoggedIn} />}
        ></Route>
        <Route path="/LoginUsingOTP" element={<LoginUsingOTP />}></Route>
        <Route path="/SignUp" element={<SignUp />}></Route>
        <Route path="/ForgotPassword" element={<ForgotPassword />}></Route>
        <Route
          path="/ResetPassword/:p1/:p2"
          element={<ResetPassword />}
        ></Route>
        <Route path="/VerifyEmail" element={<EnterOTP />}></Route>
        <Route path="/EmailSend" element={<EmailSend />}></Route>
        <Route path="/enterOTP" element={<EnterOTP />}></Route>

        {/* Pages to show to user after Login */}
        <Route
          path="/UserProfile"
          element={<UserProfile setLoggedIn={setLoggedIn} />}
        ></Route>

        {/* Pages to show to Admin */}
        <Route path="/admin/AdminPage" element={<AdminDash />}></Route>
        <Route
          path="/admin/VerifyRegistration/:p1"
          element={<VerifyRegistration />}
        ></Route>
        <Route path="/admin/MemberVerify" element={<MemberVerify />}></Route>

        {/* Error Page */}
        <Route
          path="/PageNotFound"
          element={<ErrorPage to="/PageNotFound" replace />}
        />
        <Route path="*" element={<Navigate to="/PageNotFound" replace />} />
      </Routes>

      {location.pathname !== "/admin/AdminPage" &&
      location.pathname !== "/admin/VerifyRegistration/:p1" &&
      location.pathname !== "/admin/MemberVerify" &&
      location.pathname !== "/userProfile" &&
      location.pathname !== "/admin/TicketScanner" &&
      location.pathname !== "/PageNotFound" ? (
        <Footer />
      ) : null}
    </div>
  );
}

export default App;
