import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import image from "../assets/images/navbar/IEEE_VITP_logo.png";
import NavBar_icon from "../assets/images/navbar/NavBar_icon.png";
import axios from "axios";
import "../css/navbar/NavBar2.css";

export function NavBar(props) {
  const { scrollValue, setLoggedIn, loggedIn } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [isClicked, setClicked] = useState(false);

  const toggle = () => {
    setClicked(!isClicked);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const navRef = useRef();

  const getUser = async () => {
    //function to get the user details
    const token = localStorage.getItem("token");

    let config = {
      //config to send the token in the header
      headers: {
        "Content-Type": "application/json",
        authtoken: token,
      },
    };

    let body = {};

    try {
      const response = await axios.get(
        //post request to the backend
        "https://ieeevitpune.com/user/getroute",
        config,
        body
      );
      if (response.data.success === true) {
        //if the login is successful
        setLoggedIn(true);
      } else {
        //if the login is unsuccessful
        setLoggedIn(false);
        // console.log(response.data.message);
      }
    } catch (err) {
      setLoggedIn(false);
      // console.log(err);
    }
  };

  // apply class nav-black when scroll value is less than 100
  useEffect(() => {
    if (
      scrollValue < 100 &&
      (location.pathname === "/" ||
        location.pathname === "/AboutUs" ||
        location.pathname === "/Developers" ||
        location.pathname === "/PageNotFound")
    ) {
      navRef.current.classList.add("nav-black");
    } else {
      navRef.current.classList.remove("nav-black");
    }
    // eslint-disable-next-line
  }, [scrollValue]);

  useEffect(() => {
    if (
      scrollValue < 100 &&
      (location.pathname === "/" ||
        location.pathname === "/AboutUs" ||
        location.pathname === "/Developers" ||
        location.pathname === "/PageNotFound")
    ) {
      navRef.current.classList.add("nav-black");
    } else {
      navRef.current.classList.remove("nav-black");
    }
    // eslint-disable-next-line
  }, [scrollValue]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUser();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        display:
          location.pathname === "/admin/AdminPage" ||
          location.pathname === "/admin/VerifyRegistration/:p1" ||
          location.pathname === "/admin/MemberVerify" ||
          location.pathname === "/PageNotFound"
            ? "none"
            : "flex",
      }}
      ref={navRef}
      className="NavBar"
    >
      <div>
        <img
          src={image}
          onClick={() => {
            navigate("/");
          }}
          className="Logo"
          alt="IEEE VIT'P"
        />
      </div>

      <div className="Elements">
        <div className={`navList navbarDesktop  ${isClicked ? "" : "hideNav"}`}>
          <Link
            to="/"
            className={`${location.pathname === "/" ? "active" : ""}`}
            onClick={toggle}
          >
            Home
          </Link>

          <Link
            to="/Events"
            className={`${location.pathname === "/Events" ? "active" : ""}`}
            onClick={toggle}
          >
            Events
          </Link>

          <Link
            to="/AboutUs"
            className={`${location.pathname === "/AboutUs" ? "active" : ""}`}
            onClick={toggle}
          >
            About Us
          </Link>

          <Link
            to="/Activities"
            className={`${location.pathname === "/Activities" ? "active" : ""}`}
            onClick={toggle}
          >
            Activities
          </Link>

          {loggedIn ? (
            <>
              <Link
                to="/userProfile"
                className={`${
                  location.pathname === "/userProfile" ? "active" : ""
                }`}
                onClick={toggle}
              >
                Your Account
              </Link>
            </>
          ) : (
            <Link
              to="/Login"
              className={`${location.pathname === "/Login" ? "active" : ""}`}
              onClick={toggle}
            >
              Login
            </Link>
          )}
        </div>
        <div className="UnderLine_container">
          <div className="UnderLine" />
        </div>
        <div className="nav-btn" onClick={toggle}>
          <img src={NavBar_icon} alt="NavBar" className="nav-btn" />
        </div>
      </div>
    </div>
  );
}
