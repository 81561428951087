import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AdminCard = ({
  id,
  name,
  email,
  phone,
  image_link,
  setShowPopup,
  graduationYear,
  getMembers,
}) => {
  const notify = (
    e //function to display the success messages
  ) =>
    toast.success(e, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const approveMembership = async (id) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        authtoken: localStorage.getItem("token"),
      },
    };

    let body = {};

    try {
      const response = await axios.post(
        //post request to the backend
        "https://ieeevitpune.com/" + "admin/verifyMember/" + id,
        body,
        config
      );
      if (response.data.success === true) {
        notify(response.data.msg);
        await getMembers();
        // console.log(response);
      } else {
        notify(response.data.msg);
        // console.log(response);
      }
    } catch (err) {
      notify(err.data.data.msg);
      // console.log(err);
    }
  };

  return (
    <div className="admin-card">
      <div className="card-inner-div">
        <div className="left-div">
          <p>Name:</p>
        </div>
        <div className="right-div">
          <p>{name}</p>
        </div>
      </div>
      <div className="card-inner-div">
        <div className="left-div">
          <p>Email:</p>
        </div>
        <div className="right-div">
          <p>{email}</p>
        </div>
      </div>
      <div className="card-inner-div">
        <div className="left-div">
          <p>Phone:</p>
        </div>
        <div className="right-div">
          <p>{phone}</p>
        </div>
      </div>

      <div className="card-inner-div">
        <div className="left-div">
          <p>Graduation Year:</p>
        </div>
        <div className="right-div">
          <p>{graduationYear}</p>
        </div>
      </div>

      <div className="card-inner-div">
        <div className="left-div">
          <p>Image:</p>
        </div>
        <div className="right-div">
          <img
            onClick={() => {
              setShowPopup(image_link);
            }}
            className="image"
            src={image_link}
            alt="membership proof"
          />
        </div>
      </div>

      <div className="button-holder">
        <button className="approve" onClick={() => approveMembership(id)}>
          Approve
        </button>
      </div>
    </div>
  );
};
