import React, { useState, useEffect } from "react";
import "../css/admin/admin.css";
import { NavbarAdmin } from "../components/NavbarAdmin";
import { VerifyCard } from "../components/VerifyCard";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Loader } from "../components/Loader";

export const AdminPage = () => {
  const [showPopup, setShowPopup] = useState("");
  const [registrations, setRegistrations] = useState([]);
  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();

  const { p1 } = useParams(); // Getting the event id from the url

  const getRegistrations = async () => {
    let config = {
      //config to send the token in the header
      headers: {
        "Content-Type": "application/json",
        authtoken: localStorage.getItem("token"),
      },
    };

    let body = {};

    try {
      const response = await axios.get(
        //post request to the backend
        "https://ieeevitpune.com/" + "admin/getUserRegisterForEvent/" + p1,
        config,
        body
      );
      if (response.data.success === true) {
        setRegistrations(response.data.data.Userdetails);
        setLoader(false);
      } else {
        // console.log(response);
        navigate("/admin/adminPage");
      }
    } catch (err) {
      // console.log(err);
      navigate("/admin/adminPage");
    }
  };

  const CheckAdmin = async () => {
    setLoader(true);
    let config = {
      //config to send the token in the header
      headers: {
        "Content-Type": "application/json",
        authtoken: localStorage.getItem("token"),
      },
    };

    let body = {};

    try {
      const response = await axios.get(
        //post request to the backend
        "https://ieeevitpune.com/" + "admin/checkAdmin",
        config,
        body
      );
      if (response.data.success === false) {
        navigate("/PageNotFound");
      }
    } catch (err) {
      navigate("/PageNotFound");
    }
    setLoader(false);
  };

  useEffect(() => {
    CheckAdmin();
    getRegistrations();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <NavbarAdmin />
      {loader && <Loader />}
      <div className="page-holder">
        <h1 className="title">User register for Event</h1>
        <div
          // className="show-image"
          // className={{ display: `${showPopup.length == 0 ? "none" : "fixed"}` }}
          className={`show-image ${showPopup.length === 0 && "hide"}`}
        >
          {/* show cross button */}

          <span
            className="close"
            onClick={() => {
              setShowPopup("");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#f00"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </span>

          <img src={showPopup} alt="taj" />
        </div>

        <div className="admin-cards-holder">
          {registrations.map((element, index) => (
            <VerifyCard
              key={index}
              name={element.name}
              email={element.email}
              phone={element.phoneNumber}
              image_link={element.membershipProof}
              setShowPopup={setShowPopup}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AdminPage;
